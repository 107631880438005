import { Success } from './../models/success';
import { Component, OnInit, Renderer2 } from '@angular/core';
import { HelperService } from '../services/helper.service';
declare let $: any;

@Component({
  selector: 'app-success-modal',
  template: `
<div class="alert-modal">
    <div id="SuccessAlert" class="modal fade" tabindex="-1" role="dialog" data-backdrop="static" data-keyboard=false>
        <div class="modal-dialog modal-sm" role="document">
            <div class="modal-content">
                <div class="modal-body">
                    <h1 class="text-center capitalize">{{success.header}}</h1>
                    <p class="capitalize">{{success.message}}</p>
                </div>
                <div class="modal-footer single-button-footer">
                    <div class="alert-modal__buttons">
                        <button type="button" class="btn btn--ml-primary" data-dismiss="modal" (click)="onOKClick()">Close</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>`
})

export class SuccessModalComponent {
  success: Success = new Success();

  constructor(
    private helperService: HelperService,
    private renderer: Renderer2
  ) {
    this.helperService.getShowSuccessModal()
      .subscribe(result => {
        this.success = result;
        this.openModal();
      }
      );
  }

  onOKClick(): void {
    // tslint:disable-next-line:curly
    if (this.success.callback) this.success.callback.call(this);
    this.closeModal();
  }

  openModal(): void {
    $('#SuccessAlert').modal('show');
  }

  closeModal(): void {
    $('#SuccessAlert').modal('hide');
  }
}
