import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { HttpModule } from '@angular/http';
import { ActivatedRoute, Router }       from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { environment }                  from '../../environments/environment';
import { SignIn }                       from '../models/signin';
import { Auth }                         from '../models/auth';
import { AppService, HelperService, AuthGuard } from '../services/index';
import { StorageService } from './../services/storage.service';
import { BaseComponent } from '../base.component';
import { WindowRef } from '../shared/windowRef';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.css']
})
export class LoginPageComponent extends BaseComponent {
	formGroup: FormGroup;
   signIn: SignIn = new SignIn();
   statusMessage: string;
   constructor(
    private authService: AuthGuard,
		private windowRef: WindowRef,
		protected route: ActivatedRoute,
    private _router: Router,
    private appService: AppService,
    private helperService: HelperService,
    private storageService: StorageService
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.setUpForm();
    
    if (this.authService.isLoggedIn) {
      this._router.navigate(['/dashboard']);
    }
	}

	onSubmit() {
        this.statusMessage = '';
        if (this.formGroup.valid) {
          this.signIn.username = this.formGroup.value.email;
          this.signIn.password = this.formGroup.value.password;
            this.appService.SignIn(this.signIn)
            	.then(response => {
                if (response) {
                  this.storageService.store(response);
                  this.authService.login(this.signIn);
                } else {
                  this.statusMessage = 'Unable to login, incorrect username or password';
                }
            	}).catch(err => {
                this.statusMessage = 'Unable to login, incorrect username or password';
                this.helperService.setShowModal(err); 
              });
        }
    }

setUpForm() {
       this.formGroup = new FormGroup({
	      email: new FormControl(this.signIn.username, [
	        Validators.required,
	        Validators.pattern(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)
	      ]),
	      password: new FormControl(this.signIn.password, [
			    Validators.required,
	        Validators.minLength(8),
	        Validators.maxLength(20)
	      ])
    	});
	}
}
