import { Job } from './job';
import { TimeSheet } from './timesheet';

export class CandidateTbl {
    candidateID:            number;
    candidateFirstName:     string;
    candidateMiddleName:    string;
    candidateLastName:      string;
    firstLastName:          string;
    dateofBirth:            DateTimeFormat;
    email_Address:          string;
    freelance:              boolean;
    permanent:              boolean;
}
