import { OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';
import * as moment from 'moment';
declare let $: any;

export class BaseComponent implements OnInit {
    constructor() { }

    ngOnInit() { }

    onKeyPressAcceptNumberOnly(event: KeyboardEvent): any {
        const charCode = event.which ? event.which : event.keyCode;

        return charCode >= 48 && charCode <= 57;
    }

     // Below two functions are to display cross in the invalid field
    isFieldInvalid(form: FormGroup, field: string) {
       return form.get(field).invalid && form.get(field).dirty && form.get(field).touched;
	}

	displayFeedback(form: FormGroup, field: string) {
	  return {
	    'has-feedback': this.isFieldInvalid(form, field),
        'has-error' : this.isFieldInvalid(form, field)
	  };
	}

    isFieldDateInvalid(form: FormGroup, field: string) {
        const currentDate = moment(form.get(field).value, 'DD/MM/YYYY');
        const minDateMoment = moment().add(-16, 'years');

        if (currentDate.isAfter(minDateMoment) && form.get(field).touched) {
            return true;
        }

  		return form.get(field).invalid && (form.get(field).dirty || form.get(field).touched);
	}

	displayDateFeedback(form: FormGroup, field: string) {
	  return {
	    'has-feedback': this.isFieldDateInvalid(form, field),
        'has-error' : this.isFieldDateInvalid(form, field)
	  };
	}
}

