import { Job } from './job';
import { TimeSheet } from './timesheet';

export class WeekCommencing {
    wkid: number;
    weekCommencing1: DateTimeFormat;
    title: string;
    status: number;
    jobid: number;
    job: Job;
    timesheets: TimeSheet[]; 
}
