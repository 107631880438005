import { Injectable }       from '@angular/core';
import { Observable }       from 'rxjs/Observable';
import { Subject }          from 'rxjs/Subject';
import { WindowRef }        from '../shared/windowRef';
import { Success } from '../models/success';

@Injectable()
export class HelperService {
  private isLoadingSubject = new Subject<boolean>();
  private isShowingModalSubject = new Subject<string>();
  private isShowingSuccessModalSubject = new Subject<Success>();


  constructor (private windowRef: WindowRef) {}

  setShowModal(isShowModal: string) {
    this.isShowingModalSubject.next(isShowModal);
  }

  getShowModal(): Observable<string> {
    return this.isShowingModalSubject.asObservable();
  }

  setLoading(isLoading: boolean) {
    this.isLoadingSubject.next(isLoading);
  }

  getLoading(): Observable<boolean> {
    return this.isLoadingSubject.asObservable();
  }

  setShowSuccessModal(showModal: Success) {
    this.isShowingSuccessModalSubject.next(showModal);
  }

  getShowSuccessModal(): Observable<Success> {
    return this.isShowingSuccessModalSubject.asObservable();
  }

}
