import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { LoginPageComponent } from './login-page/login-page.component';
import { NgBootstrapFormValidationModule } from 'ng-bootstrap-form-validation';
import { StorageServiceModule } from 'angular-webstorage-service';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import {A2Edatetimepicker} from 'ng2-eonasdan-datetimepicker';

import { AppService } from './services/app.service';
import { StoreService } from './services/store.service' 
import { HelperService } from './services/helper.service'; 
import { AuthGuard } from './services/auth-guard.service';
import { StorageService } from './services/storage.service';

import { WindowRef } from './shared/windowRef';
import { DashboardComponent } from './dashboard/dashboard.component';
import { TimesheetComponent } from './timesheet/timesheet.component';
import { HeaderComponent } from './header/header.component';
import { ForgotPasswordComponent } from './account/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './account/reset-password/reset-password.component';
import { LoadingSpinnerComponent } from './shared/loading-spinner.component';
import { WeeklyViewComponent } from './timesheet/weekly-view/weekly-view.component';
import { SuccessModalComponent } from './notification/success-modal.component';

// define the available routes
const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login',  component: LoginPageComponent },
  { path: 'account/forgotpassword',  component: ForgotPasswordComponent },
  { path: 'account/resetpassword',  component: ResetPasswordComponent },
  { path: 'dashboard',  component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'timesheet',  component: TimesheetComponent, canActivate: [AuthGuard] },
  { path: 'timesheet/view',  component: WeeklyViewComponent }
];

@NgModule({
  declarations: [
    AppComponent,
    LoginPageComponent,
    ResetPasswordComponent,
    ForgotPasswordComponent,
    DashboardComponent,
    TimesheetComponent,
    HeaderComponent,
    LoadingSpinnerComponent,
    WeeklyViewComponent,
    SuccessModalComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    NgBootstrapFormValidationModule.forRoot(),
    HttpModule,
    RouterModule.forRoot(routes),
    StorageServiceModule,
    A2Edatetimepicker
  ],
  providers: [
    WindowRef,
    AppService,
    StoreService,
    StorageService,
    HelperService,
    AuthGuard,
    {provide: LocationStrategy, useClass: HashLocationStrategy}
   ],
  bootstrap: [AppComponent]
})
export class AppModule { }
