import {AbstractControl} from '@angular/forms';
import { ValidatorFn, ValidationErrors } from '@angular/forms/src/directives/validators';

export class PasswordValidation {
    passwordVerifyTest(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
          const verifyPassword = control.value;
          if (verifyPassword) {
            const newPassword = control.root.get('password').value;
    
            if (verifyPassword !== newPassword) {
              return {PasswordVerify: true};
            }
          }
          return null;
        };
      }
}
