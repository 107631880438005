import { Observable } from 'rxjs/Observable';
import { SignIn } from '../models/signin';
import { TimeSheet } from '../models/timesheet';

export abstract class IAppService {
    abstract SignIn(signIn: SignIn): Promise<any>;
    abstract ForgotPassword(email: string): Promise<any>;
    abstract ResetPassword(signIn: SignIn): Promise<any>;
    abstract GetWeeksCommencing():  Promise<any>;
    abstract GetWeekCommencing(wkid: string):  Promise<any>;
    abstract GetJob(jobid: string): Promise<any>;
    abstract GetUserDetails(): Promise<any>;
    abstract GetTimesheet(wkid: string, jobid: string): Promise<any>;
    abstract GetCompanyInfo(companyid: number): Promise<any>;
    abstract GetJobTitle(jobtitleid: number): Promise<any>;
    abstract PutTimesheet(timesheet: TimeSheet, signed: boolean): Promise<any>;
    abstract UploadFile(file: FormData, timesheet: TimeSheet): Promise<TimeSheet>;
}
