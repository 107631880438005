import { environment } from './../../environments/environment';
import { Component, OnInit } from '@angular/core';
import { StorageService } from '../services/storage.service';
import { AuthGuard } from '../services';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {
  headerImage: string;
  sitelink: string;
  contactEmail: string;
  contactPhone: string;
  contactMobile: string;
  constructor(
    private authService: AuthGuard,
    private storageService: StorageService) {
    if (environment.mockURL.indexOf('com.au') > 1) {
      this.headerImage = 'assets/header-au-new.jpg';
      this.sitelink = 'http://www.thecreativestore.com.au/';
      this.contactEmail = 'louise@thecreativestore.com.au';
      this.contactPhone = '(02) 8278 7514';
      this.contactMobile = '0420 336 744';
    } else {
      this.headerImage = 'assets/header-nz-new.jpg';
      this.sitelink = 'http://www.thecreativestore.co.nz/';
      this.contactEmail = 'louise@thecreativestore.co.nz';
      this.contactPhone = '09 365 1077';
      this.contactMobile = '021 245 0089';
    }
  }

  ngOnInit() {
  }

  logout() {
    this.storageService.remove();
    this.authService.logout();
  }

}
