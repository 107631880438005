export class CompanyInfo {
    companyID:          number;
    company_Name:       string;
    street_Address:     string;
    postal_Address:     string;
    web_Site:           string;
    phone_Number:       string;
    fax_Number:         string;
    twitter:            string;
    google_:            string;
    notes:              string;
    status:             string;
    dateAdded:          DateTimeFormat;
    statusID:           number;
    createdBy:          number;
    createdDate:        DateTimeFormat;
}
