import { Injectable }       from '@angular/core';
import { environment }      from '../../environments/environment';
import 'rxjs/add/operator/map';

/* Service references */
import { StoreService } from './store.service';
import { IAppService }      from './iapp.service';
import { HelperService }      from './helper.service';
import { SignIn }           from '../models/signin';
import { TimeSheet } from './../models/timesheet';
import { WeekCommencing } from './../models/weekCommencing';

@Injectable()
export class AppService extends IAppService {
  /*Global declaration*/
  prod = environment.production;
  service: IAppService;
  constructor (
    private helperService: HelperService,
    private storeService: StoreService,
  ) {
    super();
    this.service = this.storeService;
  }

  SetLoadingSpinner(): void {
    this.helperService.setLoading(true);
  }

  SignIn(signIn: SignIn): Promise<any> {
    this.SetLoadingSpinner();
    return this.service.SignIn(signIn);
  }

  ResetPassword(signIn: SignIn): Promise<any> {
    this.SetLoadingSpinner();
    return this.service.ResetPassword(signIn);
  }

  GetUserDetails(): Promise<any> {
    this.SetLoadingSpinner();
    return this.service.GetUserDetails();
  }

  ForgotPassword(email: string): Promise<any> {
    this.SetLoadingSpinner();
    return this.service.ForgotPassword(email);
  }

  GetWeeksCommencing(): Promise<any> {
    this.SetLoadingSpinner();
    return this.service.GetWeeksCommencing();
  }

  GetWeekCommencing(wkid: string): Promise<any> {
    this.SetLoadingSpinner();
    return this.service.GetWeekCommencing(wkid);
  }

  GetJob(jobid: string): Promise<any> {
    this.SetLoadingSpinner();
    return this.service.GetJob(jobid);
  }

  PutTimesheet(timesheet: TimeSheet, signed: boolean): Promise<any> {
    this.SetLoadingSpinner();
    return this.service.PutTimesheet(timesheet, signed);
  }

  GetTimesheet(wkid: string, jobid: string): Promise<any> {
    this.SetLoadingSpinner();
    return this.service.GetTimesheet(wkid, jobid);
  }

  GetCompanyInfo(companyid: number): Promise<any> {
    this.SetLoadingSpinner();
    return this.service.GetCompanyInfo(companyid);
  }

  GetJobTitle(jobtitleid: number): Promise<any> {
    this.SetLoadingSpinner();
    return this.service.GetJobTitle(jobtitleid);
  }

  UploadFile(file: FormData, timesheet: TimeSheet): Promise<TimeSheet> {
    this.SetLoadingSpinner();
    return this.service.UploadFile(file, timesheet);
  }
}
