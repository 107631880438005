import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { AppService, HelperService, AuthGuard } from '../../services/index';
import { BaseComponent } from '../../base.component';
import { WindowRef } from '../../shared/windowRef';

import { WeekCommencing } from '../../models/weekCommencing';
import { Job } from '../../models/job';
import { CandidateTbl } from './../../models/candidatetbl';
import { TimeSheet } from './../../models/timesheet';
import { CompanyInfo } from './../../models/companyInfo';
import { JobTitle } from './../../models/jobTitle';
import { Observable } from '../../../../node_modules/rxjs/Observable';

@Component({
  selector: 'app-weekly-view',
  templateUrl: './weekly-view.component.html',
  styleUrls: ['../timesheet.component.css']
})
export class WeeklyViewComponent extends BaseComponent {
  formGroup: FormGroup;
  weekCommencing: WeekCommencing = new WeekCommencing();
  job: Job = new Job();
  timeSheet: TimeSheet = new TimeSheet();
  wkid: string;
  jobid: string;
  candid: string;
  headerImage: string;
  constructor(
    private windowRef: WindowRef,
    protected _activatedRoute: ActivatedRoute,
    private _router: Router,
    private appService: AppService,
    private helperService: HelperService,
    private authService: AuthGuard
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.authService.isLoggedIn.subscribe(value => {
      if (!value) {
        if (environment.mockURL.indexOf('com.au') > 1) {
          this.headerImage = 'assets/header-au-new.jpg';
        } else {
          this.headerImage = 'assets/header-nz-new.jpg';
        }
      }
    });

    this._activatedRoute.queryParams.subscribe(param => {
      this.wkid = param['wkid'];
      this.jobid = param['jobid'];
      this.candid = param['candid'];
    });

    this.job.jobTitle = new JobTitle();
    this.job.clientCompanyInfo = new CompanyInfo();
    this.job.weekCommencing1 = new WeekCommencing();
    this.timeSheet.candidateTbl = new CandidateTbl();

    this.getdetails();

    this.timeSheet.jobID = this.jobid;
    this.timeSheet.wkid = this.wkid;

    this.formGroup = new FormGroup({
      mondayStartTime: new FormControl(this.timeSheet.mondayStartTime),
      mondayLunchFrom: new FormControl(this.timeSheet.mondayLunchFrom),
      mondayLunchTo: new FormControl(this.timeSheet.mondayLunchTo),
      mondayFinishTime: new FormControl(this.timeSheet.mondayFinishTime),
      tuesdayStartTime: new FormControl(this.timeSheet.tuesdayStartTime),
      tuesdayLunchFrom: new FormControl(this.timeSheet.tuesdayLunchFrom),
      tuesdayLunchTo: new FormControl(this.timeSheet.tuesdayLunchTo),
      tuesdayFinishTime: new FormControl(this.timeSheet.tuesdayFinishTime),
      wednesdayStartTime: new FormControl(this.timeSheet.wednesdayStartTime),
      wednesdayLunchFrom: new FormControl(this.timeSheet.wednesdayLunchFrom),
      wednesdayLunchTo: new FormControl(this.timeSheet.wednesdayLunchTo),
      wednesdayFinishTime: new FormControl(this.timeSheet.wednesdayFinishTime),
      thursdayStartTime: new FormControl(this.timeSheet.thursdayStartTime),
      thursdayLunchFrom: new FormControl(this.timeSheet.thursdayLunchFrom),
      thursdayLunchTo: new FormControl(this.timeSheet.thursdayLunchTo),
      thursdayFinishTime: new FormControl(this.timeSheet.thursdayFinishTime),
      fridayStartTime: new FormControl(this.timeSheet.fridayStartTime),
      fridayLunchFrom: new FormControl(this.timeSheet.fridayLunchFrom),
      fridayLunchTo: new FormControl(this.timeSheet.fridayLunchTo),
      fridayFinishTime: new FormControl(this.timeSheet.fridayFinishTime),
      saturdayStartTime: new FormControl(this.timeSheet.saturdayStartTime),
      saturdayLunchFrom: new FormControl(this.timeSheet.saturdayLunchFrom),
      saturdayLunchTo: new FormControl(this.timeSheet.saturdayLunchTo),
      saturdayFinishTime: new FormControl(this.timeSheet.saturdayFinishTime),
      sundayStartTime: new FormControl(this.timeSheet.sundayStartTime),
      sundayLunchFrom: new FormControl(this.timeSheet.sundayLunchFrom),
      sundayLunchTo: new FormControl(this.timeSheet.sundayLunchTo),
      sundayFinishTime: new FormControl(this.timeSheet.sundayFinishTime),
      mondayTotal: new FormControl(this.timeSheet.mondayTotal),
      tuesdayTotal: new FormControl(this.timeSheet.tuesdayTotal),
      wednesdayTotal: new FormControl(this.timeSheet.wednesdayTotal),
      thursdayTotal: new FormControl(this.timeSheet.thursdayTotal),
      fridayTotal: new FormControl(this.timeSheet.fridayTotal),
      saturdayTotal: new FormControl(this.timeSheet.saturdayTotal),
      sundayTotal: new FormControl(this.timeSheet.sundayTotal),
      total: new FormControl(this.timeSheet.total)
    });
  }

  getdetails() {
    this.appService.GetTimesheet(this.wkid, this.jobid)
      .then(response => {
        if (response) {
          this.timeSheet = response;
          this.job = this.timeSheet.job;
        }
      }).catch(err => { this.helperService.setShowModal(err); });
  }
}
