import { StorageService } from './storage.service';
import { Injectable } from '@angular/core';
import {
	CanActivate,
	ActivatedRouteSnapshot,
	RouterStateSnapshot,
	Router
  } from '@angular/router';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Observable } from 'rxjs/Observable';
import { SignIn } from '../models/signin';

import 'rxjs/add/operator/take';
import 'rxjs/add/operator/map';

@Injectable()
export class AuthGuard implements CanActivate {
	private loggedIn = new BehaviorSubject<boolean>(false);
	constructor(
		private router: Router,
		private storageService: StorageService
  ) {}

  get isLoggedIn() {
		this.loggedIn.next(this.storageService.isTokenExpired());
    return this.loggedIn.asObservable();
	}

	get signedIn(){
		return this.storageService.isTokenExpired();
	}
	
	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	  ): Observable<boolean> {
		return this.isLoggedIn
		  .take(1)
		  .map((isLoggedIn: boolean) => {
			if (!isLoggedIn) {
			  this.router.navigate(['/login']);
			  return false;
			}
			return true;
		  });
	}

	login(user: SignIn) {
		if (user.username !== '' && user.password !== '' ) {
		  this.loggedIn.next(true);
		  this.router.navigate(['/dashboard']);
		}
	}
	
	logout() {
		this.loggedIn.next(false);
		this.router.navigate(['/login']);
	}
}
