import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { HttpModule } from '@angular/http';
import { ActivatedRoute, Router }       from '@angular/router';
import { FormControl, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { environment }                  from '../../../environments/environment';
import { SignIn }                       from '../../models/signin';
import { Auth }                         from '../../models/auth';
import { AppService, HelperService, AuthGuard } from '../../services/index';
import { StorageService } from './../../services/storage.service';
import { BaseComponent } from '../../base.component';
import { WindowRef } from '../../shared/windowRef';
import { PasswordValidation } from './../../shared/validation';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['../../login-page/login-page.component.css']
})
export class ResetPasswordComponent extends BaseComponent {
  formGroup: FormGroup;
  signIn: SignIn = new SignIn();
  validation: PasswordValidation = new PasswordValidation();
  statusMessage: string;
  confirmPassword: string;
  loading: boolean;
  constructor(
    private authService: AuthGuard,
		private windowRef: WindowRef,
		protected route: ActivatedRoute,
    private _router: Router,
    private appService: AppService,
    private helperService: HelperService,
    private storageService: StorageService
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.setUpForm();
    this.loading = false;
    this.route.queryParams.subscribe(param => {
      this.signIn.username = param['id'];
    });
    
    if (this.authService.signedIn) {
      this._router.navigate(['/dashboard']);
    }
  }
  
  onSubmit() {
    this.loading = true;
    this.statusMessage = '';
    if (this.formGroup.valid) {
      this.signIn.password = this.formGroup.value.password;
        this.appService.ResetPassword(this.signIn)
          .then(response => {
            if (response) {
              this._router.navigate(['/login']);
            } else {
              this.statusMessage = 'Unable to reset password.';
            }
          }).catch(err => {this.helperService.setShowModal(err); });
    }
  }

  setUpForm() {
    this.formGroup = new FormGroup({
      email: new FormControl(this.signIn.username, [
        Validators.required,
        Validators.pattern(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)
      ]),
      password: new FormControl(this.signIn.password, [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(20)
      ]),
      confirmPassword: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(20),
        Validators.compose([Validators.required, this.validation.passwordVerifyTest()] )
      ])
    });
  }
}
