import { AuthGuard } from './auth-guard.service';
import { StorageService } from './storage.service';
import { Injectable }   from '@angular/core';
import { Observable }     from 'rxjs/Observable';
import { IAppService }  from './iapp.service';
import { HelperService } from './helper.service';
import { environment } from '../../environments/environment';
import { SignIn }       from '../models/signin';
import { MockAPIUrls } from '../settings/app.settings';
import { Http, Headers, RequestOptions } from '@angular/http';

import 'rxjs/add/operator/toPromise';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/finally';
import { TimeSheet } from '../models/timesheet';

@Injectable()
export class StoreService extends IAppService {
    private url: string = environment.mockURL;
	private headers: Headers = environment.headers;
    private options: RequestOptions;
    private tokenValue: string;
    constructor(
        private http: Http, 
        private helperService: HelperService,
        private storageService: StorageService,
        private authGuard: AuthGuard
    ) {        
        super();
    }

    checkOauth(): any {
            if (this.authGuard.isLoggedIn) {
                const token = this.storageService.retrieveToken();
                if (token !== '') {
                    if (this.tokenValue === undefined || this.tokenValue === '') {
                        this.tokenValue = 'Bearer ' + token;
                        this.headers.append('Authorization', this.tokenValue);
                    }
                    this.options = new RequestOptions({ headers: this.headers });
                }
            }
    }

    handleResponse(): void {
        // turn off loading spinner
        this.helperService.setLoading(false);
    }    
    
    SignIn(signIn: SignIn): Promise<any> {
        let body = new URLSearchParams();
        body.set('grant_type', 'password');
        body.set('username', signIn.username);
        body.set('password', signIn.password);

        this.options = new RequestOptions({ headers: environment.oAuthheaders });
      
	  return this.http.post(`${this.url}${MockAPIUrls.LoginUrl}`, body.toString(), this.options)	  
					.finally(() => this.handleResponse())
					.toPromise()
					.then(response => response.json())
					.catch(this.handleError);   
    }

    ResetPassword(signIn: SignIn): Promise<any> {
        let body = new URLSearchParams();
        body.set('id', signIn.username);
        body.set('password', signIn.password);

        this.options = new RequestOptions({ headers: environment.oAuthheaders });
      
	  return this.http.post(`${this.url}${MockAPIUrls.ResetPasswordUrl}`, body.toString(), this.options)	  
					.finally(() => this.handleResponse())
					.toPromise()
					.then(response => response.json())
					.catch(this.handleError);   
    }
	
	ForgotPassword(email: string): Promise<any> {
	    return this.http.post(`${this.url}${MockAPIUrls.ForgotPasswordUrl}${email}`, '', this.options)	  
					.finally(() => this.handleResponse())
					.toPromise()
					.then(response => response.json())
					.catch(this.handleError);  
    }

    GetUserDetails(): Promise<any> {
        this.checkOauth();
        return this.http.get(`${this.url}${MockAPIUrls.GetUserDetailsUrl}`, this.options)
            .finally(() => this.handleResponse())
            .toPromise()
			.then(response => response.json())
			.catch(this.handleError);
    }
    
    GetWeeksCommencing(): Promise<any> {
        this.checkOauth();
        return this.http.get(`${this.url}${MockAPIUrls.GetWeekCommencingUrl}`, this.options)
            .finally(() => this.handleResponse())
            .toPromise()
			.then(response => response.json())
			.catch(this.handleError);
    }

    GetWeekCommencing(wkid: string): Promise<any> {
        this.checkOauth();
        // let myParams = new URLSearchParams();
        // myParams.append('id', wkid);

        // this.options = new RequestOptions({ headers: this.headers, params: myParams });

        return this.http.get(`${this.url}${MockAPIUrls.GetWeekCommencingUrl}${wkid}`, this.options)
            .finally(() => this.handleResponse())
            .toPromise()
			.then(response => response.json())
			.catch(this.handleError);
    }

    GetJob(jobid: string): Promise<any> {
        this.checkOauth();

        return this.http.get(`${this.url}${MockAPIUrls.GetJobUrl}${jobid}`, this.options)
            .finally(() => this.handleResponse())
            .toPromise()
			.then(response => response.json())
			.catch(this.handleError);
    }

    GetTimesheet(wkid: string, jobid: string): Promise<any> {
        this.checkOauth();
        let params = new URLSearchParams();
        params.append('jobid', jobid);

        return this.http.get(`${this.url}${MockAPIUrls.GetTimeSheetUrl}${wkid}?${params}`, this.options)
            .finally(() => this.handleResponse())
            .toPromise()
			.then(response => response.json())
			.catch(this.handleError);
    }

    GetCompanyInfo(companyid: number): Promise<any> {
        this.checkOauth();

        return this.http.get(`${this.url}${MockAPIUrls.GetCompanyInfoUrl}${companyid}`, this.options)
            .finally(() => this.handleResponse())
            .toPromise()
			.then(response => response.json())
			.catch(this.handleError);
    }

    GetJobTitle(jobtitleid: number): Promise<any> {
        this.checkOauth();

        return this.http.get(`${this.url}${MockAPIUrls.GetJobTitleUrl}${jobtitleid}`, this.options)
            .finally(() => this.handleResponse())
            .toPromise()
			.then(response => response.json())
			.catch(this.handleError);
    }

    PutTimesheet(timesheet: TimeSheet, signed: boolean): Promise<any> {
        this.checkOauth();

        let params = new URLSearchParams();
        params.append('id', `${timesheet.timeSheetID}`);
        params.append('timesheetRecevied', `${signed}`);

	  return this.http.put(`${this.url}${MockAPIUrls.PutTimesheetUrl}?${params}`, timesheet, this.options)	  
					.finally(() => this.handleResponse())
					.toPromise()
					.then(response => response.json())
					.catch(this.handleError);
    }

    UploadFile(file: FormData, timesheet: TimeSheet): Promise<TimeSheet> {
        this.checkOauth();
        
        const uploadFileHeaders = new Headers();
        uploadFileHeaders.append('Authorization', this.tokenValue);
        this.options = new RequestOptions({ headers: uploadFileHeaders });

        return this.http.post(`${this.url}${MockAPIUrls.UploadFileUrl}${timesheet.timeSheetID}?` +
                `jobId=${timesheet.jobID}&candidateId=${timesheet.candidateID}`, file, this.options)
            .finally(() => this.handleResponse())
            .toPromise()
			.then(response => response.json())
			.catch(this.handleError);
    }

    private handleError(error: any): Promise<any> {
        // console.error('An error occured', error);
        return Promise.reject(error.message || error);
    }
}
