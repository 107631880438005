import { Injectable } from '@angular/core';

@Injectable()
export class StorageService {
  private tokenKey: string;

  constructor() {
    this.tokenKey = 'app_token';
  }
    public store(content: Object) {        
        localStorage.setItem(this.tokenKey, JSON.stringify(content));
        const time_to_login = (new Date()).getTime() + 3600000;
        localStorage.setItem('timer', JSON.stringify(time_to_login));
    }

    private retrieve() {
        let storedToken: string = localStorage.getItem(this.tokenKey);
        if (!storedToken) {
          throw new Error('no token found');
      }
        return storedToken;
    }

    public retrieveToken() {

        let currentTime: number = (new Date()).getTime(), token = null;
        try {
            let storedToken = JSON.parse(this.retrieve());
            if (storedToken.ttl < currentTime) {
              throw new Error('invalid token found');
            }
            token = storedToken.access_token;
        } catch (err) {
            console.error(err);
        }
        return token;

    }

    isTokenExpired(): boolean {
		let currentTime: number = (new Date()).getTime(), token = null;
        try {
            let storedToken = JSON.parse(this.retrieve());
            let timer = JSON.parse(localStorage.getItem('timer'));
            if (timer < currentTime) {
              return false;
            }
        } catch (err) {
            return false;
        }
        return true;
    }
    
    remove(): any {
        localStorage.removeItem(this.tokenKey);
        localStorage.removeItem('timer');
    }
}
