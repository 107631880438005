import { WeekCommencing } from './weekCommencing';
import { CompanyInfo } from './companyInfo';
import { JobTitle } from './jobTitle';

export class Job {
    job_ID: number;
    companyID: number;
    contactID: number;
    jobTitleID: number;
    job_Description: string;
    startDate: DateTimeFormat;
    endDate: DateTimeFormat;
    assignmentStartDate: DateTimeFormat;
    assignmentEndDate: DateTimeFormat;
    jobTitle: JobTitle;
    clientCompanyInfo: CompanyInfo;
    weekCommencing1: WeekCommencing
}
