import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { KeyValueChanges, KeyValueDiffer, KeyValueDiffers } from '@angular/core';
import { HttpModule } from '@angular/http';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from '../../environments/environment';
import { AppService, HelperService } from '../services/index';
import { StorageService } from './../services/storage.service';
import { BaseComponent } from '../base.component';
import { WindowRef } from '../shared/windowRef';

import { WeekCommencing } from '../models/weekCommencing';
import { Job } from '../models/job';
import { CandidateTbl } from './../models/candidatetbl';
import { TimeSheet } from './../models/timesheet';
import { CompanyInfo } from './../models/companyInfo';
import { JobTitle } from './../models/jobTitle';
import { Success } from '../models/success';

@Component({
  selector: 'app-timesheet',
  templateUrl: './timesheet.component.html',
  styleUrls: ['./timesheet.component.css']
})
export class TimesheetComponent extends BaseComponent {
  formGroup: FormGroup;
  weekCommencing: WeekCommencing = new WeekCommencing();
  job: Job = new Job();
  candidateTbl: CandidateTbl = new CandidateTbl();
  timeSheet: TimeSheet = new TimeSheet();
  statusMessage: string;
  wkid: string;
  jobid: string;
  checked: boolean;
  progress: number;
  message: string;
  file: FormData;
  fileExist: boolean;
  success: Success = new Success();
  private timesheetDiffer: KeyValueDiffer<string, any>;
  a2eOptions = { format: 'HH:mm a' };
  submitted: boolean = false;
  constructor(
    private windowRef: WindowRef,
    protected _activatedRoute: ActivatedRoute,
    private _router: Router,
    private appService: AppService,
    private helperService: HelperService,
    private storageService: StorageService,
    private differs: KeyValueDiffers
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.job.jobTitle = new JobTitle();
    this.job.clientCompanyInfo = new CompanyInfo();

    this._activatedRoute.queryParams.subscribe(param => {
      this.wkid = param['wkid'];
      this.jobid = param['jobid'];
    });
    this.fileExist = false;
    this.getdetails();

    this.timeSheet.jobID = this.jobid;
    this.timeSheet.wkid = this.wkid;
    this.timesheetDiffer = this.differs.find(this.timeSheet).create();

    this.formGroup = new FormGroup({
      mondayStartTime: new FormControl(this.timeSheet.mondayStartTime),
      mondayLunchFrom: new FormControl(this.timeSheet.mondayLunchFrom),
      mondayLunchTo: new FormControl(this.timeSheet.mondayLunchTo),
      mondayFinishTime: new FormControl(this.timeSheet.mondayFinishTime),
      tuesdayStartTime: new FormControl(this.timeSheet.tuesdayStartTime),
      tuesdayLunchFrom: new FormControl(this.timeSheet.tuesdayLunchFrom),
      tuesdayLunchTo: new FormControl(this.timeSheet.tuesdayLunchTo),
      tuesdayFinishTime: new FormControl(this.timeSheet.tuesdayFinishTime),
      wednesdayStartTime: new FormControl(this.timeSheet.wednesdayStartTime),
      wednesdayLunchFrom: new FormControl(this.timeSheet.wednesdayLunchFrom),
      wednesdayLunchTo: new FormControl(this.timeSheet.wednesdayLunchTo),
      wednesdayFinishTime: new FormControl(this.timeSheet.wednesdayFinishTime),
      thursdayStartTime: new FormControl(this.timeSheet.thursdayStartTime),
      thursdayLunchFrom: new FormControl(this.timeSheet.thursdayLunchFrom),
      thursdayLunchTo: new FormControl(this.timeSheet.thursdayLunchTo),
      thursdayFinishTime: new FormControl(this.timeSheet.thursdayFinishTime),
      fridayStartTime: new FormControl(this.timeSheet.fridayStartTime),
      fridayLunchFrom: new FormControl(this.timeSheet.fridayLunchFrom),
      fridayLunchTo: new FormControl(this.timeSheet.fridayLunchTo),
      fridayFinishTime: new FormControl(this.timeSheet.fridayFinishTime),
      saturdayStartTime: new FormControl(this.timeSheet.saturdayStartTime),
      saturdayLunchFrom: new FormControl(this.timeSheet.saturdayLunchFrom),
      saturdayLunchTo: new FormControl(this.timeSheet.saturdayLunchTo),
      saturdayFinishTime: new FormControl(this.timeSheet.saturdayFinishTime),
      sundayStartTime: new FormControl(this.timeSheet.sundayStartTime),
      sundayLunchFrom: new FormControl(this.timeSheet.sundayLunchFrom),
      sundayLunchTo: new FormControl(this.timeSheet.sundayLunchTo),
      sundayFinishTime: new FormControl(this.timeSheet.sundayFinishTime),
      mondayTotal: new FormControl(this.timeSheet.mondayTotal),
      tuesdayTotal: new FormControl(this.timeSheet.tuesdayTotal),
      wednesdayTotal: new FormControl(this.timeSheet.wednesdayTotal),
      thursdayTotal: new FormControl(this.timeSheet.thursdayTotal),
      fridayTotal: new FormControl(this.timeSheet.fridayTotal),
      saturdayTotal: new FormControl(this.timeSheet.saturdayTotal),
      sundayTotal: new FormControl(this.timeSheet.sundayTotal),
      total: new FormControl(this.timeSheet.total)
    });
  }

  ngDoCheck(): void {
    const changes = this.timesheetDiffer.diff(this.timeSheet);
    if (changes) {
      this.timesheetChanged(changes);
    }
  }

  timesheetChanged(changes: KeyValueChanges<string, any>) {
    this.timeSheet.mondayTotal = this.getTotalTime(this.timeSheet.mondayStartTime, this.timeSheet.mondayFinishTime,
      this.timeSheet.mondayLunchFrom, this.timeSheet.mondayLunchTo);
    this.timeSheet.tuesdayTotal = this.getTotalTime(this.timeSheet.tuesdayStartTime, this.timeSheet.tuesdayFinishTime,
      this.timeSheet.tuesdayLunchFrom, this.timeSheet.tuesdayLunchTo);
    this.timeSheet.wednesdayTotal = this.getTotalTime(this.timeSheet.wednesdayStartTime, this.timeSheet.wednesdayFinishTime,
      this.timeSheet.wednesdayLunchFrom, this.timeSheet.wednesdayLunchTo);
    this.timeSheet.thursdayTotal = this.getTotalTime(this.timeSheet.thursdayStartTime, this.timeSheet.thursdayFinishTime,
      this.timeSheet.thursdayLunchFrom, this.timeSheet.thursdayLunchTo);
    this.timeSheet.fridayTotal = this.getTotalTime(this.timeSheet.fridayStartTime, this.timeSheet.fridayFinishTime,
      this.timeSheet.fridayLunchFrom, this.timeSheet.fridayLunchTo);
    this.timeSheet.saturdayTotal = this.getTotalTime(this.timeSheet.saturdayStartTime, this.timeSheet.saturdayFinishTime,
      this.timeSheet.saturdayLunchFrom, this.timeSheet.saturdayLunchTo);
    this.timeSheet.sundayTotal = this.getTotalTime(this.timeSheet.sundayStartTime, this.timeSheet.sundayFinishTime,
      this.timeSheet.sundayLunchFrom, this.timeSheet.sundayLunchTo);

    this.timeSheet.total = Math.round((this.timeSheet.mondayTotal + this.timeSheet.tuesdayTotal + this.timeSheet.wednesdayTotal
      + this.timeSheet.thursdayTotal + this.timeSheet.fridayTotal + this.timeSheet.saturdayTotal + this.timeSheet.sundayTotal) * 100) / 100;
    this.toggleAMPM();
  }

  getdetails() {
    this.appService.GetWeekCommencing(this.wkid)
      .then(response => {
        if (response) {
          this.weekCommencing = response;
        }
      }).catch(err => { this.helperService.setShowModal(err); });

    this.appService.GetTimesheet(this.wkid, this.jobid)
      .then(response => {
        if (response) {
          this.job = response.job;
          if (response.timeSheetID !== undefined && response.timeSheetID !== 0) {
            this.timeSheet = response;
            this.manipulateDatetoString();
          }
        }
      }).catch(err => { this.helperService.setShowModal(err); });

    this.appService.GetUserDetails()
      .then(response => {
        if (response) {
          this.candidateTbl = response;
          if (this.timeSheet.candidateID === undefined || this.timeSheet.candidateID == null) {
            this.timeSheet.candidateID = this.candidateTbl.candidateID;
          }
        }
      }).catch(err => { this.helperService.setShowModal(err); });
  }

  onSave() {
    if (!this.submitted) {
      this.putTimesheet(false);
    }
  }

  onSubmit() {
    if (!this.submitted) {
      this.submitted = true;
      this.putTimesheet(true);
    }
  }

  putTimesheet(signed: boolean) {
    this.statusMessage = '';
    if (signed) {
      this.success.message = 'Your timesheet was sent successfully.';
    } else {
      this.success.message = 'Your timesheet is saved!';
    }
    if (this.formGroup.valid) {
      if (this.timeSheet.timeSheetID === undefined) {
        this.timeSheet.timeSheetID = 0;
      }
      this.manipulateDatetoString();
      if (this.fileExist) {
        this.fileExist = false;
        this.appService.UploadFile(this.file, this.timeSheet)
          .then(uploadedResponse => {
            if (uploadedResponse) {
              this.timeSheet.timeSheetID = uploadedResponse.timeSheetID;
              this.timeSheet.attachmentLink = uploadedResponse.attachmentLink;
              this.appService.PutTimesheet(this.timeSheet, signed).then(response => {
                if (response) {
                  this.helperService.setShowSuccessModal(this.success);
                  this._router.navigate(['/dashboard']);
                } else {
                  this.statusMessage = 'Error occrued, please try again.';
                }
              }).catch(err => { this.helperService.setShowModal(err); });
            } else {
              this.statusMessage = 'Unable to upload this file, please select a valid file.';
            }
          }).catch(err => { this.helperService.setShowModal(err); });
      } else {
        this.appService.PutTimesheet(this.timeSheet, signed)
          .then(response => {
            if (response) {
              this.helperService.setShowSuccessModal(this.success);
              this._router.navigate(['/dashboard']);
            } else {
              this.statusMessage = 'Error occrued, please try again.';
            }
          }).catch(err => { this.helperService.setShowModal(err); });
      }
    }
  }

  uploadFile(files) {
    if (files.length === 0) {
      return;
    }
    this.file = new FormData();
    for (let file of files) {
      this.fileExist = true;
      this.file.append('Attachment', file);
    }
  }

  getTotalTime(startTime, endTime, lunchStartTime, lunchEndTime) {
    let lunchtime = 0;
    const d1 = new Date('01/01/2000 ' + this.tranformTime(startTime));
    const d2 = new Date('01/01/2000 ' + this.tranformTime(endTime));
    let totalTime = d2.valueOf() - d1.valueOf();
    if (lunchStartTime !== undefined && lunchEndTime !== undefined) {
      const d3 = new Date('01/01/2000 ' + this.tranformTime(lunchStartTime));
      const d4 = new Date('01/01/2000 ' + this.tranformTime(lunchEndTime));
      lunchtime = d4.valueOf() - d3.valueOf()
      if (lunchtime > 0) {
        totalTime = totalTime - lunchtime;
      }
    }
    if (totalTime > 0) {
      return Math.round((totalTime / 1000 / 60 / 60) * 100) / 100;
    } else {
      return null;
    }
  }

  terms(val: boolean) {
    this.checked = val;
  }

  manipulateDatetoString() {
    this.timeSheet.mondayStartTime = this.tranformTime(this.timeSheet.mondayStartTime);
    this.timeSheet.mondayLunchFrom = this.tranformTime(this.timeSheet.mondayLunchFrom);
    this.timeSheet.mondayLunchTo = this.tranformTime(this.timeSheet.mondayLunchTo);
    this.timeSheet.mondayFinishTime = this.tranformTime(this.timeSheet.mondayFinishTime);
    this.timeSheet.tuesdayStartTime = this.tranformTime(this.timeSheet.tuesdayStartTime);
    this.timeSheet.tuesdayLunchFrom = this.tranformTime(this.timeSheet.tuesdayLunchFrom);
    this.timeSheet.tuesdayLunchTo = this.tranformTime(this.timeSheet.tuesdayLunchTo);
    this.timeSheet.tuesdayFinishTime = this.tranformTime(this.timeSheet.tuesdayFinishTime);
    this.timeSheet.wednesdayStartTime = this.tranformTime(this.timeSheet.wednesdayStartTime);
    this.timeSheet.wednesdayLunchFrom = this.tranformTime(this.timeSheet.wednesdayLunchFrom);
    this.timeSheet.wednesdayLunchTo = this.tranformTime(this.timeSheet.wednesdayLunchTo);
    this.timeSheet.wednesdayFinishTime = this.tranformTime(this.timeSheet.wednesdayFinishTime);
    this.timeSheet.thursdayStartTime = this.tranformTime(this.timeSheet.thursdayStartTime);
    this.timeSheet.thursdayLunchFrom = this.tranformTime(this.timeSheet.thursdayLunchFrom);
    this.timeSheet.thursdayLunchTo = this.tranformTime(this.timeSheet.thursdayLunchTo);
    this.timeSheet.thursdayFinishTime = this.tranformTime(this.timeSheet.thursdayFinishTime);
    this.timeSheet.fridayStartTime = this.tranformTime(this.timeSheet.fridayStartTime);
    this.timeSheet.fridayLunchFrom = this.tranformTime(this.timeSheet.fridayLunchFrom);
    this.timeSheet.fridayLunchTo = this.tranformTime(this.timeSheet.fridayLunchTo);
    this.timeSheet.fridayFinishTime = this.tranformTime(this.timeSheet.fridayFinishTime);
    this.timeSheet.saturdayStartTime = this.tranformTime(this.timeSheet.saturdayStartTime);
    this.timeSheet.saturdayLunchFrom = this.tranformTime(this.timeSheet.saturdayLunchFrom);
    this.timeSheet.saturdayLunchTo = this.tranformTime(this.timeSheet.saturdayLunchTo);
    this.timeSheet.saturdayFinishTime = this.tranformTime(this.timeSheet.saturdayFinishTime);
    this.timeSheet.sundayStartTime = this.tranformTime(this.timeSheet.sundayStartTime);
    this.timeSheet.sundayLunchFrom = this.tranformTime(this.timeSheet.sundayLunchFrom);
    this.timeSheet.sundayLunchTo = this.tranformTime(this.timeSheet.sundayLunchTo);
    this.timeSheet.sundayFinishTime = this.tranformTime(this.timeSheet.sundayFinishTime);
  }

  tranformTime(date: string) {
    if (new Date(date).toString() === 'Invalid Date') {
      return date;
    }
    if (!date || date === '') {
      return '';
    }
    return new Date(date).getHours().toString() + ':' + new Date(date).getMinutes().toString() + ':00';
  }

  checkIsAM(date: string): boolean {
    if (date === '')
      return false;
    if (new Date(date).toString() === 'Invalid Date') {
      const dateform = new Date('01/01/2000 ' + date);
      return dateform.getHours() < 12
    }
    return new Date(date).getHours() < 12;
  }

  toggleAMPM(): any {
    this.timeSheet.mondayStartAMPM = this.checkIsAM(this.timeSheet.mondayStartTime) ? 'AM' : 'PM';
    this.timeSheet.mondayLunchStartAMPM = this.checkIsAM(this.timeSheet.mondayLunchFrom) ? 'AM' : 'PM';
    this.timeSheet.mondayLunchFinishAMPM = this.checkIsAM(this.timeSheet.mondayLunchTo) ? 'AM' : 'PM';
    this.timeSheet.mondayFinishAMPM = this.checkIsAM(this.timeSheet.mondayFinishTime) ? 'AM' : 'PM';

    this.timeSheet.tuesdayStartAMPM = this.checkIsAM(this.timeSheet.tuesdayStartTime) ? 'AM' : 'PM';
    this.timeSheet.tuesdayLunchStartAMPM = this.checkIsAM(this.timeSheet.tuesdayLunchFrom) ? 'AM' : 'PM';
    this.timeSheet.tuesdayLunchFinishAMPM = this.checkIsAM(this.timeSheet.thursdayLunchTo) ? 'AM' : 'PM';
    this.timeSheet.tuesdayFinishAMPM = this.checkIsAM(this.timeSheet.tuesdayFinishTime) ? 'AM' : 'PM';

    this.timeSheet.wednesdayStartAMPM = this.checkIsAM(this.timeSheet.wednesdayStartTime) ? 'AM' : 'PM';
    this.timeSheet.wednesdayLunchStartAMPM = this.checkIsAM(this.timeSheet.wednesdayLunchFrom) ? 'AM' : 'PM';
    this.timeSheet.wednesdayLunchFinishAMPM = this.checkIsAM(this.timeSheet.wednesdayLunchTo) ? 'AM' : 'PM';
    this.timeSheet.wednesdayFinishAMPM = this.checkIsAM(this.timeSheet.wednesdayFinishTime) ? 'AM' : 'PM';

    this.timeSheet.thursdayStartAMPM = this.checkIsAM(this.timeSheet.thursdayStartTime) ? 'AM' : 'PM';
    this.timeSheet.thursdayLunchStartAMPM = this.checkIsAM(this.timeSheet.thursdayLunchFrom) ? 'AM' : 'PM';
    this.timeSheet.thursdayLunchFinishAMPM = this.checkIsAM(this.timeSheet.thursdayLunchTo) ? 'AM' : 'PM';
    this.timeSheet.thursdayFinishAMPM = this.checkIsAM(this.timeSheet.thursdayFinishTime) ? 'AM' : 'PM';

    this.timeSheet.fridayStartAMPM = this.checkIsAM(this.timeSheet.fridayStartTime) ? 'AM' : 'PM';
    this.timeSheet.fridayLunchStartAMPM = this.checkIsAM(this.timeSheet.fridayLunchFrom) ? 'AM' : 'PM';
    this.timeSheet.fridayLunchFinishAMPM = this.checkIsAM(this.timeSheet.fridayLunchTo) ? 'AM' : 'PM';
    this.timeSheet.fridayFinishAMPM = this.checkIsAM(this.timeSheet.fridayFinishTime) ? 'AM' : 'PM';

    this.timeSheet.saturdayStartAMPM = this.checkIsAM(this.timeSheet.saturdayStartTime) ? 'AM' : 'PM';
    this.timeSheet.saturdayLunchStartAMPM = this.checkIsAM(this.timeSheet.saturdayLunchFrom) ? 'AM' : 'PM';
    this.timeSheet.saturdayLunchFinishAMPM = this.checkIsAM(this.timeSheet.saturdayLunchTo) ? 'AM' : 'PM';
    this.timeSheet.saturdayFinishAMPM = this.checkIsAM(this.timeSheet.saturdayFinishTime) ? 'AM' : 'PM';

    this.timeSheet.sundayStartAMPM = this.checkIsAM(this.timeSheet.sundayStartTime) ? 'AM' : 'PM';
    this.timeSheet.sundayLunchStartAMPM = this.checkIsAM(this.timeSheet.sundayLunchFrom) ? 'AM' : 'PM';
    this.timeSheet.sundayLunchFinishAMPM = this.checkIsAM(this.timeSheet.sundayLunchTo) ? 'AM' : 'PM';
    this.timeSheet.sundayFinishAMPM = this.checkIsAM(this.timeSheet.sundayFinishTime) ? 'AM' : 'PM';

    // extra custom rules
    if (!this.timeSheet.mondayStartTime)
      this.timeSheet.mondayStartAMPM = 'AM'
    if (!this.timeSheet.tuesdayStartTime)
      this.timeSheet.tuesdayStartAMPM = 'AM'
    if (!this.timeSheet.wednesdayStartTime)
      this.timeSheet.wednesdayStartAMPM = 'AM'
    if (!this.timeSheet.thursdayStartTime)
      this.timeSheet.thursdayStartAMPM = 'AM'
    if (!this.timeSheet.fridayStartTime)
      this.timeSheet.fridayStartAMPM = 'AM'
    if (!this.timeSheet.saturdayStartTime)
      this.timeSheet.saturdayStartAMPM = 'AM'
    if (!this.timeSheet.sundayStartTime)
      this.timeSheet.sundayStartAMPM = 'AM'
  }
}
