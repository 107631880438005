import { Component } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { AuthGuard } from './services/index';
import { LoadingSpinnerComponent } from './shared/loading-spinner.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  isLoggedIn$: Observable<boolean>;
  class: string;

  constructor(private authService: AuthGuard) { }

  ngOnInit() {
    this.isLoggedIn$ = this.authService.isLoggedIn;
  }

  onLogout() {
    this.authService.logout();
  }
}
