export const AppSettings = {
    Provide : '/programme/getavailableprogrammes',
    AnonymousBasePath: '/enrolment/StartEnrolment#',
    EnrolmentHubBasePath: '/enrolment/apex/enrolmenthub#'
  };

  export const MockAPIUrls = {
    LoginUrl:             'token',
    GetWeekCommencingUrl: 'api/weekcommencing/',
    GetJobUrl:          'api/jobs/',
    GetUserDetailsUrl: 'api/account/UserDetails/',
    ForgotPasswordUrl: 'api/account/forgotpassword/?email=',
    ResetPasswordUrl: 'api/account/ResetPassword',
    GetTimeSheetUrl: 'api/timesheet/bywkid/',
    PutTimesheetUrl:  'api/timesheet/',
    GetCompanyInfoUrl: 'api/ClientCompanyInfoes/',
    GetJobTitleUrl: 'api/JobTitles/',
    UploadFileUrl: 'api/timesheet/uploadfiles/'
  };
