import { CandidateTbl } from './candidatetbl';
import { Job } from './job';
import { WeekCommencing } from './weekCommencing'
import { Time } from '@angular/common/src/i18n/locale_data_api';

export class TimeSheet {
    timeSheetID:        number;
    wkid:               string;
    jobID:              string;
    candidateID:        number;
    mondayStartTime:    string;
    mondayLunchFrom:    string;
    mondayLunchTo:      string;
    mondayFinishTime:   string;
    tuesdayStartTime:   string;
    tuesdayLunchFrom:   string;
    tuesdayLunchTo:     string;
    tuesdayFinishTime:  string;
    wednesdayStartTime: string;
    wednesdayLunchFrom: string;
    wednesdayLunchTo:   string;
    wednesdayFinishTime: string;
    thursdayStartTime:  string;
    thursdayLunchFrom:  string;
    thursdayLunchTo:    string;
    thursdayFinishTime: string;
    fridayStartTime:    string;
    fridayLunchFrom:    string;
    fridayLunchTo:      string;
    fridayFinishTime:   string;
    saturdayStartTime:  string;
    saturdayLunchFrom:  string;
    saturdayLunchTo:    string;
    saturdayFinishTime: string;
    sundayStartTime:    string;
    sundayLunchFrom:    string;
    sundayLunchTo:      string;
    sundayFinishTime:   string;
    mondayStartAMPM:    string;
    mondayLunchStartAMPM:    string;
    mondayLunchFinishAMPM:      string;
    mondayFinishAMPM:   string;
    tuesdayStartAMPM:   string;
    tuesdayLunchStartAMPM:   string;
    tuesdayLunchFinishAMPM:     string;
    tuesdayFinishAMPM:  string;
    wednesdayStartAMPM: string;
    wednesdayLunchStartAMPM: string;
    wednesdayLunchFinishAMPM:   string;
    wednesdayFinishAMPM: string;
    thursdayStartAMPM:  string;
    thursdayLunchStartAMPM:  string;
    thursdayLunchFinishAMPM:    string;
    thursdayFinishAMPM: string;
    fridayStartAMPM:    string;
    fridayLunchStartAMPM:    string;
    fridayLunchFinishAMPM:      string;
    fridayFinishAMPM:   string;
    saturdayStartAMPM:  string;
    saturdayLunchStartAMPM:  string;
    saturdayLunchFinishAMPM:    string;
    saturdayFinishAMPM: string;
    sundayStartAMPM:    string;
    sundayLunchStartAMPM:    string;
    sundayLunchFinishAMPM:      string;
    sundayFinishAMPM:   string;
    mondayTotal:        number;
    tuesdayTotal:       number;
    wednesdayTotal:     number;
    thursdayTotal:      number;
    fridayTotal:        number;
    saturdayTotal:      number;
    sundayTotal:        number;
    total:              number;
    createdOn:          Date;
    modifiedOn:         Date;
    job: Job;
    candidateTbl: CandidateTbl;
    attachmentLink: string;
}
